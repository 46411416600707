<template>
  <div class="d-flex flex-column overflow-auto vh-100">
    <sticky-header :title="$route.meta.title">
      <template #buttons>
      </template>
    </sticky-header>
    <div class="flex-fill p-3">
      <b-table sortable responsive outlined class="mb-0"
               :items="formattedJobs"
               :fields="['Location/Property', 'name', 'date', 'expected_start', 'expected_end', 'actual_start', 'actual_end', 'hours', 'entry', '_']">
        <template #cell(actual_start)="row">
          <b-form-timepicker v-model="row.item.actual_start" />
        </template>
        <template #cell(actual_end)="row">
          <b-form-timepicker v-model="row.item.actual_end" />
        </template>
        <template #cell(hours)="row">
          {{ (row.item.actual_start && row.item.actual_end) ? (Math.floor(moment(row.item.date + ' ' + row.item.actual_end, 'DD/MM/Y HH:mm:ss').diff(moment(row.item.date + ' ' + row.item.actual_start, 'DD/MM/Y HH:mm:ss'), 'minutes')/60) + 'h ' + (moment(row.item.date + ' ' + row.item.actual_end, 'DD/MM/Y HH:mm:ss').diff(moment(row.item.date + ' ' + row.item.actual_start, 'DD/MM/Y HH:mm:ss'), 'minutes')%60) + 'm') : "Ongoing" }}
        </template>
        <template #cell(entry)="row">
          {{ row.item.no_entry ? 'No' : 'Yes' }}
        </template>
        <template #cell(_)="row">
          <b-button target="_blank" :href="'/jobs/details/' + row.item.id" class="mr-1" variant="primary" size="sm">View</b-button>
          <b-button class="mr-1" variant="success" size="sm" @click="approve(row.item.attendance_id, true, moment(row.item.date + ' ' + row.item.actual_start, 'DD/MM/Y HH:mm:ss').format(), moment(row.item.date + ' ' + row.item.actual_end, 'DD/MM/Y HH:mm:ss').format())">Approve</b-button>
          <b-button :id="'reject_record_' + row.item.id" variant="danger" size="sm" @click="approve(row.item.attendance_id, false)" v-b-tooltip.hover title="This action will delete the attendance record for this cleaner and will NOT delete the job itself.">Delete</b-button>
        </template>
      </b-table>
      <b-pagination-nav class="mt-3" :link-gen="paginationLinkGen" :number-of-pages="lastPage" use-router v-if="lastPage > 1" />
    </div>
  </div>
</template>

<script>
import StickyHeader from "@/components/StickyHeader.vue";
import {mapActions, mapState} from "vuex";
import moment from "moment/moment";
import pickBy from "lodash/pickBy";
import identity from "lodash/identity";

export default {
  components: {StickyHeader},
  data() {
    return {
      formattedJobs: []
    };
  },
  computed: {
    ...mapState('jobs', ['data', 'loading', 'lastPage']),
  },
  methods: {
    ...mapActions('jobs', ['fetch', 'approveAttendance', 'rejectAttendance', 'updateAttendanceStartEnd']),
    moment,
    paginationLinkGen(pageNo) {
      return pageNo === 1
        ? {name: 'job-confirmations', query: pickBy({...this.$route.params, page: null}, identity)}
        : {name: 'job-confirmations', query: {...this.$route.params, page: pageNo}};
    },
    async load() {
      await this.fetch({
        page: this.$route.query.page,
        confirmed: false
      });
    },
    async approve(attendanceId, state, start, end) {
      if (state) {
        await this.updateAttendanceStartEnd({id: attendanceId, start, end})
        await this.approveAttendance(attendanceId);
      } else {
        await this.rejectAttendance(attendanceId);
      }

      await this.load();
    }
  },
  async mounted() {
    await this.load();
  },
  watch: {
    async '$route'(newValue, value) {
      if (newValue.name === value.name && newValue.query.page !== value.query.page) {
        await this.load();
      }
    },
    data(data) {
      this.formattedJobs = [];

      let self = this;

      data.forEach(function(job) {
        job.attendance.forEach(function(attendance) {
          const minutesWorked = moment(attendance.end).diff(moment(attendance.start), 'minutes');
          self.formattedJobs.push({
            id: job.id,
            attendance_id: attendance.id,
            'Location/Property': job?.customer?.name ?? job?.property?.name ?? 'Unknown',
            name: attendance.user.name,
            date: moment(job.started_at).format('DD/MM/Y'),
            expected_start: moment(job.started_at).format('HH:mm'),
            expected_end: moment(job.started_at).add(job?.duration_min ?? 0, 'minutes').format('HH:mm'),
            actual_start: attendance?.start ? moment(attendance.start).format('HH:mm:ss') : false,
            actual_end: attendance?.end ? moment(attendance.end).format('HH:mm:ss') : false,
            hours: (attendance?.start && attendance?.end) ? (Math.floor(minutesWorked/60) + 'h ' + (minutesWorked%60) + 'm') : 'Ongoing',
            no_entry: job.no_entry_reason
          })
        });
      })
    }
  }
}
</script>

<style scoped>

</style>
